import React from "react";
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
  SubmitHandler,
} from "react-hook-form";
import { Text, View } from "react-native";
// import RNPickerSelect from "react-native-picker-select";
// import { t } from "react-native-tailwindcss";
import Button from "../../../atoms/Button";
import Input from "../../../molecules/Input";
import { authStyles } from "../shared";
import { CustomErrors, PersonalInfoFormData } from "./RegistrationScreen";

type Props = {
  onSubmit: (data: PersonalInfoFormData) => void;
  customErrors: CustomErrors;
  setCustomErrors: (e: CustomErrors) => void;
  control: Control<PersonalInfoFormData>;
  errors: DeepMap<PersonalInfoFormData, FieldError>;
  handleSubmit: <TSubmitFieldValues extends FieldValues = PersonalInfoFormData>(
    onValid: SubmitHandler<TSubmitFieldValues>,
  ) => (e?: any) => Promise<void>;
};

export const PersonalInfoForm = ({
  onSubmit,
  customErrors,
  setCustomErrors,
  handleSubmit,
  control,
  errors,
}: Props): React.ReactElement => {
  const runCustomValidation = (mobile: string) => {
    let isValid = true;
    const newCustomErrors: CustomErrors = {
      ...customErrors,
    };

    if (!mobile.match(/(\+61)(2|4|3|7|8)[0-9]{8}/)) {
      newCustomErrors.mobilePatternMatch = true;
      isValid = false;
    } else {
      newCustomErrors.mobilePatternMatch = false;
    }

    setCustomErrors(newCustomErrors);
    return isValid;
  };

  const validate = (data: PersonalInfoFormData): boolean => {
    const mobileNumber = data.mobilePrefix + data.mobile;
    return runCustomValidation(mobileNumber);
  };

  return (
    <>
      <Controller
        name="firstName"
        control={control}
        render={({ onChange, value }) => (
          <Input
            error={errors.firstName ? "This is required." : undefined}
            onChangeText={(text: string) => onChange(text)}
            value={value}
            placeholder="First Name"
          />
        )}
        rules={{
          required: true,
        }}
      />

      <Controller
        name="lastName"
        control={control}
        render={({ onChange, value }) => (
          <Input
            error={errors.lastName ? "This is required." : undefined}
            onChangeText={(text: string) => onChange(text)}
            value={value}
            placeholder="Last Name"
          />
        )}
        rules={{
          required: true,
        }}
      />
      {/* <Controller
        name="state"
        control={control}
        render={({ onChange, value }) => (
          <RNPickerSelect
            style={{
              inputIOSContainer: regStyles.selectWrapper as TextStyle,
              inputAndroidContainer: regStyles.selectWrapper as TextStyle,
              inputIOS: regStyles.select as TextStyle,
              inputAndroid: regStyles.select as TextStyle,
              inputWeb: regStyles.webSelect as TextStyle,
            }}
            placeholder={{ label: "Select a state", value: "State" }}
            onValueChange={v => onChange(v)}
            value={value}
            items={[
              { label: "NSW", value: "NSW" },
              { label: "NT", value: "NT" },
              { label: "QLD", value: "QLD" },
              { label: "SA", value: "SA" },
              { label: "TAS", value: "TAS" },
              { label: "VIC", value: "VIC" },
              { label: "WA", value: "WA" },
              { label: "ACT", value: "ACT" },
            ]}
          />
        )}
        rules={{
          required: true,
        }}
      />
      {errors.state && (
        <Text style={[authStyles.error]}>This is required.</Text>
      )} */}
      <View style={{ display: "flex", flexDirection: "row" }}>
        <View style={{ flex: 1, width: "90%" }}>
          <Controller
            name="mobilePrefix"
            defaultValue="+61"
            control={control}
            render={({ value }) => (
              <Input style={{ width: "90%" }} value={value} editable={false} />
            )}
            rules={{
              required: true,
            }}
          />
        </View>

        <View style={{ flex: 5 }}>
          <Controller
            name="mobile"
            control={control}
            style={{ flex: 2 }}
            render={({ onChange, value }) => (
              <Input
                error={errors.mobile ? "This is required." : undefined}
                onChangeText={(text: string) => onChange(text)}
                value={value}
                placeholder="Mobile"
              />
            )}
            rules={{
              required: true,
            }}
          />
        </View>
      </View>

      <Controller
        name="country"
        control={control}
        render={({ onChange, value }) => (
          <Input
            error={errors.country ? "This is required." : undefined}
            onChangeText={(text: string) => onChange(text)}
            value={value}
            editable={false}
            placeholder="Country"
          />
        )}
        rules={{
          required: true,
        }}
      />
      {customErrors.mobilePatternMatch && (
        <Text style={[authStyles.error]}>
          Should start with +61 and be followed by min 9 chars.
        </Text>
      )}
      <Button
        onPress={handleSubmit(data => {
          if (validate(data)) {
            onSubmit(data);
          }
        })}
        label="Continue"
      />
    </>
  );
};

// const regStyles = {
//   selectWrapper: [t.selfStretch, t.mB2],
//   select: [
//     t.h11,
//     t.border,
//     t.selfStretch,
//     t.p2,
//     t.borderGray500,
//     t.roundedLg,
//     t.textBase,
//     t.textGray700,
//   ],
//   webSelect: [
//     t.h11,
//     t.border,
//     t.selfStretch,
//     t.p2,
//     t.borderGray500,
//     t.roundedLg,
//     t.textBase,
//     t.textGray700,
//     t.mB2,
//   ],
// };
