import { appConfig } from "../config/config";
import api from "./api/api";
import utils from "../utils";
import { User } from "../dtos/user";
import { Job } from "../dtos/job";

export interface CreateUserParams {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
}
export type UpdateUserResponse = {
  message: string;
};

export type CreateUserRequest = {
  firstName: string;
  lastName: string;
  mobile: string;
  country: string;
  contributionLimit?: number;
  customerReference?: string;
};

type CreateUserResponse = {
  id: string;
};

export const createUser = async (
  payload: CreateUserRequest,
): Promise<CreateUserResponse> => {
  return api.post(`user/${appConfig.tenant}/user/create`, {
    ...payload,
  });
};

export const finishOnboarding = async (): Promise<void> => {
  await api.put(`user/${appConfig.tenant}/user/finishOnboarding`);
};

export const updateUser = async (
  payload: CreateUserParams,
): Promise<UpdateUserResponse> => {
  return api.put(`user/${appConfig.tenant}/user/update`, {
    ...payload,
  });
};

export const getUser = async (): Promise<User | null> => {
  const hasAuthToken = await utils.hasAuthToken();
  if (!hasAuthToken) return null;

  return api.get<User>(`user/${appConfig.tenant}/user/get`);
};

export const setRoundupAmount = async (amount: number): Promise<User> => {
  return api.post(`user/${appConfig.tenant}/user/roundup`, {
    roundupAmountCents: amount,
  });
};

export const setDirectDebit = async (
  debitSchedule: "weekly" | "monthly" | undefined,
  debitAmountCents: number,
): Promise<User> => {
  return api.post(`user/${appConfig.tenant}/user/debit`, {
    debitSchedule,
    debitAmountCents,
  });
};

export const setDebitAccountJob = async (accountId: string): Promise<Job> => {
  return api.post<Job>(`user/${appConfig.tenant}/user/debitaccount`, {
    accountId,
  });
};
