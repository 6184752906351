import { useState } from "react";
import { Job, JobStatus } from "../dtos/job";
import { wait } from "../services/helpers";
import { getJob } from "../services/jobService";

export type UseJobHookInfo = {
  execute: (...args: any) => Promise<void>;
  status?: JobStatus;
  failReason?: string;
};

export const useJob = (
  jobBasedFunc: (...args: any) => Promise<Job>,
): UseJobHookInfo => {
  const [status, setStatus] = useState<JobStatus>();
  const [failReason, setFailReason] = useState<string>();

  const loop = async (func: () => Promise<Job>): Promise<void> => {
    return func().then(async job => {
      setStatus(job.status);
      setFailReason(job.failReason);

      if (job.status === JobStatus.Pending) {
        await wait(1000);
        return loop(func);
      }

      if (job.status === JobStatus.Fail) {
        throw new Error(
          job.failReason ?? "Something real bad must have happened",
        );
      }
    });
  };

  const execute = async (...args: any): Promise<void> => {
    const job = await jobBasedFunc(...args);
    await loop(async () => getJob(job.id));
  };

  return {
    execute,
    status,
    failReason,
  };
};
