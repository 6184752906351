import "react-native-gesture-handler";
import { StatusBar } from "expo-status-bar";
import { ToastProvider } from "react-native-toast-notifications";
import React, { useState, useEffect } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { appConfig } from "./config/config";
import useCachedResources from "./hooks/useCachedResources";
import { Navigation } from "./navigation/Navigation";
import { AppContainer } from "./components/layout/AppContainer";
import { queryClient } from "./services/api/query";
import { UserProvider, useUser } from "./context/userContext";
import { AccountsProvider, useAccounts } from "./context/accountsContext";
import { AuthProvider, useAuth } from "./context/authContext";
import { useError, ErrorProvider } from "./context/errorContext";

const Screens = () => {
  const isLoadingCache = useCachedResources();
  const { user } = useUser();
  const { accounts } = useAccounts();
  const { authenticated } = useAuth();
  const { criticalError } = useError();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isCriticalError, setIsCriticalError] = useState(false);

  // watch login state
  useEffect(() => {
    const fullyLoggedIn = !!authenticated && !!user && !!accounts;
    setIsLoggedIn(fullyLoggedIn);
  }, [authenticated, user, accounts]);

  // watch error state
  useEffect(() => {
    setIsCriticalError(!!criticalError);
  }, [criticalError]);

  if (!isLoadingCache) {
    return null;
  }

  const isOnboardingComplete =
    (isLoggedIn && user?.onboardingComplete) ?? false;

  const requiresBankReconnection =
    (isLoggedIn && user?.onboardingComplete && !user?.paymentContactId) ??
    false;

  return (
    <SafeAreaProvider>
      <AppContainer>
        <StatusBar backgroundColor={appConfig.primaryColour} />
        <Navigation
          isAuthenticated={isLoggedIn}
          isCriticalError={isCriticalError}
          onboardingComplete={isOnboardingComplete}
          requiresBankReconnection={requiresBankReconnection}
        />
      </AppContainer>
    </SafeAreaProvider>
  );
};
export default function App(): React.ReactElement | null {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ErrorProvider>
        <AuthProvider>
          <UserProvider>
            <AccountsProvider>
              <ToastProvider offsetBottom={100}>
                <Screens />
              </ToastProvider>
            </AccountsProvider>
          </UserProvider>
        </AuthProvider>
      </ErrorProvider>
    </QueryClientProvider>
  );
}
