import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import styled from "styled-components/native";
import { useToast } from "react-native-toast-notifications";
import { useQueryClient } from "react-query";
import { useInstitutionsQuery } from "../../../../hooks/queries/useInstitutionsQuery";
import { Institution } from "../../../../dtos/institution";
import BankSearchForm from "./BankSearchForm";
import { ScreenContainer } from "../../../layout/ScreenContainer";
import { ExtraLargeText } from "../../../atoms/ExtraLargeText";
import { BankLoginForm } from "./BankLoginForm";
import { SetupScreensParamList } from "../../../../navigation/setup-screens";
import { Footer } from "../../../molecules/Footer";
import {
  convertErrorCodeToErrorMessage,
  getErrorMessage,
} from "../../../../services/api/api";
import { useJob } from "../../../../hooks/useJob";
import {
  createConnectionJob,
  syncAccountsJob,
} from "../../../../services/bankService";
import { JobStatus } from "../../../../dtos/job";
import { QueryKey } from "../../../../services/api/query";
import { wait } from "../../../../services/helpers";
import { appConfig } from "../../../../config/config";
import { LargeText } from "../../../atoms/LargeText";
import { useError } from "../../../../context/errorContext";
import { JobFeedback } from "../../../organisms/JobFeedback";

const StyledConnectBankContentContainer = styled.View`
  margin-top: 30px;
  height: 70%;
`;

const StyledFooterContainer = styled.View`
  margin-top: 30px;
`;

const StyledBankHeaderText = styled(LargeText)`
  color: ${appConfig.dashboardBackground};
`;

enum FormStage {
  BankSearch,
  BankLogin,
}

export type BankLoginFormData = {
  institutionSlug: string;
  username: string;
  password: string;
};

export const ConnectBankScreen = (): React.ReactElement => {
  const connectJobContext = useJob(createConnectionJob);
  const syncJobContext = useJob(syncAccountsJob);
  const [loading, setLoading] = useState<boolean>(false);
  const [polling, setPolling] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { setCriticalError } = useError();

  const { data: institutions } = useInstitutionsQuery();
  const [filteredInstitutions, setFilteredInstitutions] = useState<
    Institution[]
  >([]);
  const [formStage, setFormStage] = useState<FormStage>(FormStage.BankSearch);
  const [selectedBank, setSelectedBank] = useState<Institution | undefined>();
  const { control, watch } = useForm();
  const searchValue = watch().search as string;
  const navigation = useNavigation<NavigationProp<SetupScreensParamList>>();
  const toast = useToast();

  // show search results
  useEffect(() => {
    const filteredInstitutionsTemp = searchValue
      ? (institutions ?? []).filter(i =>
          i?.name?.toLowerCase().includes(searchValue.toLowerCase()),
        )
      : institutions ?? [];

    setFilteredInstitutions(filteredInstitutionsTemp);
  }, [institutions, searchValue]);

  // update bank connection request job state
  useEffect(() => {
    // update polling state
    setPolling(
      loading ||
        connectJobContext.status === JobStatus.Pending ||
        syncJobContext.status === JobStatus.Pending,
    );
  }, [loading, connectJobContext.status, syncJobContext.status]);

  const connectBank = async ({ username, password }: BankLoginFormData) => {
    setLoading(true);
    try {
      // queue connect job
      await connectJobContext.execute({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        institutionSlug: selectedBank!.slug,
        username,
        password,
      });
      queryClient.invalidateQueries(QueryKey.User);

      // queue sync job
      await syncJobContext.execute();
      queryClient.invalidateQueries(QueryKey.Accounts);

      await wait(1000);
      navigation.navigate("RoundupAccountsSetup");

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } catch (e: Error & ApiError) {
      if (e.message) {
        toast.show(convertErrorCodeToErrorMessage(e.message), {
          type: "danger",
        });
        return;
      }

      toast.show("Something went wrong", {
        type: "danger",
      });
      setCriticalError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ScreenContainer>
      {polling ? (
        <>
          <StyledBankHeaderText center>
            {selectedBank?.name}
          </StyledBankHeaderText>

          <JobFeedback
            steps={[
              { title: "Verifying Credentials", context: connectJobContext },
              { title: "Retrieving Accounts", context: syncJobContext },
            ]}
            description="We are working on connecting your account. This could take a minute."
          />
        </>
      ) : (
        <>
          <ExtraLargeText center>Connect your bank</ExtraLargeText>

          <StyledConnectBankContentContainer>
            {formStage === FormStage.BankSearch && (
              <BankSearchForm
                onSelect={institution => {
                  setSelectedBank(institution);
                  setFormStage(FormStage.BankLogin);
                }}
                control={control}
                filteredInstitutions={filteredInstitutions}
              />
            )}

            {formStage === FormStage.BankLogin && (
              <BankLoginForm
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                institution={selectedBank!}
                onSubmit={async data => connectBank(data)}
              />
            )}
          </StyledConnectBankContentContainer>

          <StyledFooterContainer>
            <Footer />
          </StyledFooterContainer>
        </>
      )}
    </ScreenContainer>
  );
};
